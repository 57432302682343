import React, { Component } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import SimpleReactLightbox from "simple-react-lightbox";

import './config/ReactotronConfig';
import './App.scss';

import history from './services/history';
import Route from './routesConfig/Route';

import { store, persistor } from './store';

import HttpsRedirect from 'react-https-redirect';

const loading = () => {
  return <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>
};

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const AlterarSenha = React.lazy(() => import('./views/Pages/Login/AlterarSenha'));
const EsqueciSenha = React.lazy(() => import('./views/Pages/Login/EsqueciSenha'));
const RedefinirSenha = React.lazy(() => import('./views/Pages/Login/RedefinirSenha'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const options = {
  overlayColor: "rgb(25, 136, 124)",
  captionStyle: {
    captionColor: "#a6cfa5",
    captionFontFamily: "Raleway, sans-serif",
    captionFontSize: "22px",
    captionFontWeight: "300",
    captionFontStyle: "capitalize"
  },
  buttonsStyle: {
    buttonsBackgroundColor: "#1b5245",
    buttonsIconColor: "rgba(126, 172, 139, 0.8)"
  },
  autoplaySpeed: 1500,
  transitionSpeed: 900,
  showCaption: true,
  showThumbnails: true
};

class App extends Component {

  componentDidMount() {
    // Função que busca as listas na api
    // console.log(this.props)
    // alert('Carrega 1x')
  }


  render() {
    // console.log('===========================')
    // console.log('Validando sessao')
    // apis.apiGetSession()
    //   .then(
    //     dados => {
    //       console.log(dados)
    //     },
    //     error => {
    //       console.log(" error ")
    //     }
    //   );

    // console.log('===========================')

    return (
      <SimpleReactLightbox {...options}>
        <HttpsRedirect>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Router history={history}>
                <React.Suspense fallback={loading()}>
                  <Switch>
                    <Route exact path="/" name="Página de Login" component={props => <Login {...props} />} />
                    <Route exact path="/login" name="Página de Login" component={props => <Login {...props} />} />
                    <Route exact path="/esquecisenha" name="Página de esqueci senha" component={props => <EsqueciSenha {...props} />} />
                    <Route exact path="/redefinirsenha" name="Página de redefinição de senha" component={props => <RedefinirSenha {...props} />} />
                    <Route isPrivate exact path="/alterarsenha" name="Página de redefinição de senha" component={props => <AlterarSenha {...props} />} />
                    <Route exact path="/404" name="Página 404" component={props => <Page404 {...props} />} />
                    <Route exact path="/500" name="Página 500" component={props => <Page500 {...props} />} />
                    <Route isPrivate path="/" name="Dashboard" component={props => <DefaultLayout {...props} />} />
                  </Switch>
                </React.Suspense>
              </Router>
            </PersistGate>
          </Provider>
        </HttpsRedirect>
      </SimpleReactLightbox>
    );
  }
}

export default App;
