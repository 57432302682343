import axios from 'axios';

// axios.defaults.headers = {
//   'Content-Type': 'application/json',
//   'allow-methods': 'OPTIONS, HEAD, GET, POST, PUT, DELETE',
//   'allow-headers': 'X-Requested-With, X-Auth-Token, Content-Type, Content-Length, Authorization',
//   Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uSWQiOiI1ZmVhNjQ3Zi01YzY2LTQwOWEtYTdiOC1mZGM2NDhjZGNkZjQiLCJ1c2VySWQiOiI1ODJhMmY2ZDdmZjExOTY4MDcwMzI3OGQiLCJ1c2VybmFtZSI6ImVkdWFyZG8iLCJjbGllbnRJZCI6IjU4OTBkMWI5MTI0YjQ2NGU5NjE2Yjk3OCIsImlzQWRtaW4iOnRydWUsImVuZHBvaW50cyI6WyJsb2NhbGl6YWNhby5ub3ZpZGEuY28iLCJnZW9mZW5jZXMubm92aWRhLmNvIiwiZGFzaGJvYXJkLmFwaS5ub3ZpZGEuY28iLCJhcGlkYXNoLm5vdmlkYS5jbyIsImRhc2hib2FyZC5ub3ZpZGEuY28iLCJkYXNoLm5vdmlkYS5jbyIsIm5vdmlkYS5jbyIsImRhc2hyZWFsdGltZS5ub3ZpZGEuY28iLCJzY2hlZHVsZXIubm92aWRhLmNvIiwiYWxjYXRyYXoubm92aWRhLmNvIiwidHVybm8ubm92aWRhLmNvIiwibWVuc2FnZW0ubm92aWRhLmNvIiwic3RhdHVzZXF1aXBhbWVudG9zLm5vdmlkYS5jbyIsImJldGEubm92aWRhLmNvIl0sIm1heFNlc3Npb25zIjotMSwiY29udGV4dHMiOltdLCJpYXQiOjE1NzQ1NzQxNzh9.xU2KVrcKCXpXY5IDj9Z5_aVJ5v4zNe32LECvP8llCPk'
// }

axios.defaults.withCredentials = true

const apiClient = axios.create({
  baseURL: 'https://cliente.novida.co/api'
});

const apis = axios.create({
  baseURL: 'https://api.novida.co/'
});

const socketAddress = 'https://api.novida.co';

const auth = 'https://cliente.novida.co/api';

const api = { apiClient, apis, socketAddress, auth }

export default api;